<template>
  <div class="main">
    <p class="title">添加证书</p>
    <el-form
      ref="ruleForm"
      v-loading="fromloding"
      :model="ruleForm"
      :rules="ruleFormRules"
      label-width="150px"
      class="ruleForm"
      :validate-on-rule-change="true"
    >
      <el-form-item label="证书类型：" prop="type_id">
        <el-select
          v-model="ruleForm.type_id"
          class="select"
          clearable
          placeholder="请选择证书类型"
          @change="typeChange"
        >
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.certificateName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="证书类别/专业：" prop="certificateId">
        <el-select
          v-model="ruleForm.certificateId"
          class="select"
          clearable
          placeholder="请选择证书类别/专业"
          @change="certificateChange"
        >
          <el-option
            v-for="item in certificateList"
            :key="item.id"
            :label="item.categoryName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="证书编号：" prop="certificateNo">
          <el-input
            v-model="ruleForm.certificateNo"
            clearable
            type="text"
            class="input"
            placeholder="请输入证书编号"
          >></el-input>
        </el-form-item>
        <el-form-item label="继续教育完成日期：" prop="time">
          <el-date-picker
            v-model="ruleForm.time"
            type="date"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="证书上传：" prop="certificateImage">
          <div>
            <img-upload
              v-model="ruleForm.imgUrl"
              :size="'small'"
              :tips="'请上传 JPG、JPGE、PNG格式文件'"
              :limit="1"
              :limitSize="5"
              @fileList="fileList"
            ></img-upload>
          </div>
        </el-form-item>
      <div class="btn-wrap">
        <!-- <el-button style="width: 120px" @click="cancel">取消</el-button> -->
        <el-button type="primary" style="width: 120px" @click="btnSubmit"
          >提交</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import { getCategory, getType } from "../../../api/myCenter";
import ImgUpload from "@/components/ImageUpload";
import uploadDefault from '../../../assets/upload_default.png'
export default {
  name: "AddCertificate",
  components: {
    ImgUpload,
  },
  data() {
    return {
      certificateImage: '', // 证书图片路径
      typeList: [], //证书类型数据
      typeId: null,
      certificateList: [], // 证书类别/专业数据
      certificateId: null,
      loading: false,
      fromloding: false,
      ruleForm: {
        imgUrl: uploadDefault,
        type_id: "", // 证书类型
        certificateId: "", // 证书类别/专业
        certificateNo: '',
        time: ''
      },
      ruleFormRules: {
        type_id: [
          { required: true, message: '证书类型不能为空', trigger: 'change' }
        ],
        certificateId: [
          { required: true, message: '证书类别/专业不能为空', trigger: 'change' }
        ],
        certificateNo: [
          { required: true, message: '证书编号不能为空', trigger: 'blur' }
        ],
        time: [
          { required: true, message: '继续教育完成日期不能为空', trigger: 'change' }
        ],
      },
      pickerOptions: {
        onPick: (obj) => {
          this.pickerMinDate = new Date(obj.minDate).getTime()
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            const day1 = 14 * 24 * 3600 * 1000
            const maxTime = this.pickerMinDate + day1
            const minTime = this.pickerMinDate - day1
            return time.getTime() > maxTime || time.getTime() < minTime
          }
        }
      }
    };
  },
  created() {
    this.getType();
  },
  methods: {
    getType() {
      getType().then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.code === 0) {
            this.typeList = res.data.data.rows;
          }
        }
      });
    },
    getCategory(id) {
      const obj = {
        certificateId: id,
      };
      getCategory(obj).then((res) => {
        this.certificateList = []
        if (res.status === 200) {
          if (res.data.code === 0) {
            this.certificateList = res.data.data.rows;
          }
        }
      });
    },
    //类型选择
    typeChange(val) {
      this.typeId = val
      this.certificateList = []
      this.ruleForm.certificateId = ''
      this.getCategory(this.typeId);
    },
    //专业选择
    certificateChange(val) {
      this.certificateId = val
    },
    handlerDate() {

    },
    btnSubmit() {
      if(this.certificateImage === null || this.certificateImage === undefined || this.certificateImage === '') {
        this.$message({
          type: 'error',
          message: '请上传证书照片'
        })
        return
      }
      else{
        this.$refs.ruleForm.validate((vaild) => {
          if (vaild) {
            console.log('pass...');
            this.$router.push({
              path: 'certificate'
            })
          }
        })
      }
    },
    fileList(val) {
      console.log('hahaha:', val);
      this.certificateImage = val
    },
  },
};
</script>

<style  rel="stylesheet/scss" lang="scss">
.main {
  .title {
    // display: flex;
    text-align: left;
  }
  .ruleForm {
    margin-top: 20px;
    .el-form-item__content {
      text-align: left !important;
    }
    .input{
      width: 220px;
    }
    .btn-wrap {
      margin-top: 20px;
    }
  }
  .el-form-item{
      margin-left: 175px;
    }
}
</style>