// 个人中心相关api
import request from '../utils/request'
// import qs from 'qs'

//0010081-证书类别/专业
export function getCategory(params={}) {
  return request({
      method: 'GET',
      url:'certificate/category',
      params
  })
}

export function getRecord(params={}) {
    return request({
        method: 'GET',
        url:'certificate/survive/record',
        params
    })
}

//0010080-证书类型
export function getType(params={}) {
  return request({
      method: 'GET',
      url:'certificate/type',
      params
  })
}

//0010022-我的订单列表分页接口
export function getOrderList(params={}) {
  return request({
      method: 'GET',
      url:'order',
      params
  })
}

//0010104-收藏的课程
export function getCollectedCourse(params={}) {
  return request({
      method: 'GET',
      url:'userCenter/collectedCourse',
      params
  })
}

export function auditDesc(params={}) {
    return request({
        method: 'GET',
        url:'userCenter/auditDesc',
        params
    })
}

//0010105-申请换课
export function applyForExchange(data) {
  return request({
      method: 'POST',
      url: 'userCenter/applyForExchange',
      data
  })
}

//0010082-证书存续上传
export function handlerSurvive(data) {
  return request({
      method: 'POST',
      url: 'certificate/survive',
      data
  })
}

// 0010103- 我的教材列表
export function getTextBook(params={}) {
  return request({
      method: 'GET',
      url:'userCenter/textBook',
      params
  })
}

// 0010102-考试记录(我的考试)
export function getExamList(params={}) {
  return request({
      method: 'GET',
      url:'userCenter/exam',
      params
  })
}

// 学员学习进度
export function getEnterpriseStudyProgress(params={}) {
    return request({
        method: 'GET',
        url:'userCenter/enterpriseProgress',
        params
    })
}

// 学员证书
export function getEnterpriseCertificate(params={}) {
    return request({
        method: 'GET',
        url:'userCenter/enterpriseCertificate',
        params
    })
}

// 企业学员考试记录
export function getEnterpriseExamList(params={}) {
    return request({
        method: 'GET',
        url:'userCenter/enterpriseExamList',
        params
    })
}

export function getEnterpriseOptions(params={}) {
    return request({
        method: 'GET',
        url:'userCenter/enterpriseCourseOptions',
        params
    })
}